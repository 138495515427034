<template>
<div >
  <a-card  :title="title" class="left">
    
    <a-button :style="{ marginLeft: '28px' }" type="primary" ghost @click="goback" slot="extra" >返回</a-button>
    <!-- 列表 -->
      <div   class="table-page-search-wrapper">
        <a-form :model="queryParam" @keyup.enter.native="searchQuery" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-row>
            <a-col :span="5">
              <a-form-item label="通道ID">
                <a-input placeholder="请输入通道ID" v-model="queryParam.channelId"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="5">
              <a-form-item label="通道名称">
                <a-input placeholder="请输入通道名称" v-model="queryParam.channelName"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="5">
              <span style="float: left;overflow: hidden;margin-top:4px" class="table-page-search-submitButtons">
                <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
                <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <br/>
        <a-table 
        :columns="columns"
        :data-source="data"
        :rowKey="(record) => record.channelId"
        :pagination="pagination"
        @change="handleTableChange"
        >
          <template slot="avatarslot" slot-scope="text, record, index">
            <div class="anty-img-wrap">
              <a-avatar shape="square" :src="getAvatarView(record.avatar)" icon="user"/>
            </div>
          </template>

          <span slot="action" slot-scope="text, record">
            <a @click="handlePlay(record)" style="margin:20px">实时监控</a>
            <!-- <a @click="handleRecall(record)">视频回放</a> -->
          </span>
        </a-table>
      </div>
      
      
  </a-card>
  <!-- <div >
    <a-range-picker :style="{marginTop:'20px',marginBottom:'20px'}" show-time @change="PlayTimeChange"/>
    <a-button type="primary" ghost @click="goback" :style="{float:'right',marginTop:'20px',marginBottom:'20px'}">返回</a-button>
    <vue-aliplayer-v2 :source="videoUrl" ref="VueAliplayerV2" :options="options" />
  </div> -->
</div>
</template>

<script>
import VueAliplayerV2 from 'vue-aliplayer-v2';
import {
  channelList,getDicDropDown
  
} from '@/api/video'
export default {
  name: 'VedeoDetail',
  components: {
    VueAliplayerV2:VueAliplayerV2.Player
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
      queryParam: {
        page: 1,
        pageSize: 10,
      },
      data : [],
      columns:[
        {
          title: '通道ID',
          dataIndex: 'channelId',
          key: 'channelId',
          align:'center'
        },
        {
          title: '通道名称',
          dataIndex: 'channelName',
          key: 'channelName',
          align:'center'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      pagination: {
        current:1,
        total: 0,
        pageSize: 10,//每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"],//每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
      },
      title: undefined,
     
      // videoUrl:'',
      // options: {
      //   source:'',
      //   isLive:true
      // },
    }
  },
  created(){

      this.queryParam.deviceId = sessionStorage.getItem('channelDeviceId')
      this.title = sessionStorage.getItem('channelTitle') + '-通道列表'
    this.loadList(this.queryParam.deviceId)

  },

  computed: {
    
  },
  methods: {
    loadList: async function (parameter) {
      // let res = await this.$store.dispatch(
      //   "channelList",
      //   Object.assign(parameter, this.queryParam)
      // );
      // if (res.code == 200) {
      //   this.data = res.result.list
      //   this.pagination.total = res.result.total
      // }
      channelList(Object.assign(parameter, this.queryParam)).then(res=>{
         if (res.code == 200) {
        this.data = res.result.list
        this.pagination.total = res.result.total
      }
      })
    },
    //分页
    handleTableChange(pagination) {
      console.log('pagination',pagination)
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current
      this.queryParam.pageSize = pagination.pageSize
      this.loadList({})
    },
  
    // 预警时间
    onChange(date, dateString) {
      console.log(date, dateString);
    },
    // 实时监控
    handlePlay(record) {
      this.$router.push({path:"/RealtimePlay",query:{record:record} })
    },
    // 录像回放
    handleRecall(record) {
      this.$router.push({path:"/PlayBack",query:{record:record} })
    },
    // 选择回放时间
    PlayTimeChange(date, dateString){
      console.log(date, dateString);
    },
    goback(){
      this.$router.go(-1);//返回上一层
    },
    //搜索
    searchQuery(){
      this.pagination.current = 1
      this.queryParam.page = 1
      this.loadList({})
    },
    //重置
    searchReset(){
      this.queryParam.page = 1
      this.pagination.current = 1
      this.queryParam.channelId = ''
      this.queryParam.channelName =''
      this.loadList({})
    }
  },
}
</script>
<style scoped>
/* @import '~@assets/less/common.less'; */
.left {
  min-height: 85vh;
}
</style>